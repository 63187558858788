<template>
  <div class="row" style="padding-top: 0.25rem">
        <div style="width: 98.7%;margin-top: -0.7rem;">
          <v-select name="name"
            style="border: none"
            label="name"
            :disabled="disabled"
            :clearable="false"
            :searchable="true"
            :multiple="true"
            class="style-chooser"
            v-model="selected"
            @search="keyMonitor"
            @input="onSelect(selected)"
            :options="optionList"
            :filter-by="filter"
            :components="{Deselect}"
            ref="selectedEl"
          >
          <template #open-indicator="{ attributes }">
            <i class="ti-search"></i>
          </template>
          <template slot="no-options" slot-scope="{searching, search}">
            <em v-if="!searching" style="opacity: 0.5;">
              Digite para buscar um cliente
            </em>
            <em v-else-if="isLoading" style="opacity: 0.5;">
              Buscando {{ search }}
              <div class="anel-load">
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
              </div>
            </em>
            <em v-else style="opacity: 0.5;">
              Nenhuma opção encontrada para {{ search }}
            </em>
          </template>
          <template slot="option" slot-scope="option">
            <div class="d-center">
              <div v-if="selected">
                <div v-if="selectedFind(option)" style="color: #E0E0E0">
                  {{ option.name }}
                </div>
                <div v-else>
                {{ option.name }}
              </div>
              </div>
              <div v-else>
                {{ option.name }}
              </div>
            </div>
          </template>
        </v-select>
      </div>
  </div>
</template>

<script>
import 'vue-select/dist/vue-select.css'
import vSelect from 'vue-select'

export default {
  name: 'MultipleSelect',
  props: {
    rowClick: {
      type: Function,
      required: false,
      default: (row) => {
      }
    },
    delayTime: {
      type: Number,
      default: 400
    },

    placeholder: {
      type: String,
      default: global.instanceApp.$i18n.t('filter.Filter_by')
    },
    apiUrl: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    tableFields: {
      type: Array,
      default: () => {
        return []
      }
    },
    emptyMessage: {
      type: String,
      default: global.instanceApp.$i18n.t('noting selected')
    },
    noOptions: {
      type: String,
      default: 'Nenhum resultado encontrado'
    },
    fetchOptions: {
      type: Function,
      required: false,
      default: () => {
        return false
      }
    },
    searchField: {
      type: String,
      default: ''
    },
    labelField: {
      type: String,
      default: 'name'
    },
    idField: {
      type: String,
      default: 'id'
    },
    httpMethod: {
      type: String,
      default: 'get',
      validator: (value) => {
        return ['get', 'post'].indexOf(value) > -1
      }
    },
    value: {
      type: Array,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Can the user clear the slected property.
     * @type {Boolean}
     */
    clearable: {
      type: Boolean,
      default: true
    },
    /**
     * Enable/disable filtering the options.
     * @type {Boolean}
     */
    searchable: {
      type: Boolean,
      default: true
    }
  },
  components: {
    vSelect
  },
  data () {
    return {
      Deselect: {
        render: createElement => createElement('span', 'x')
      },
      optionList: [],
      isLoading: true,
      waiting: null,
      searchTerm: null,
      selectedValue: null,
      id: 1,
      timer: 0,
      selected: null
    }
  },
  methods: {
    keyMonitor: function (search, loading) {
      if (search.length > 0) {
        let _this = this
        _this.isLoading = true
        // Can get key pressed on "event.key"
        if (this.waiting) {
          window.clearTimeout(this.waiting)
        }
        this.waiting = setTimeout(function () {
          _this.isLoading = true
          if (search) {
            _this.fetchOptions(search).then(res => {
              _this.optionList = res
              _this.isLoading = false
            }).catch(() => {
              _this.isLoading = false
            })
          } else {
            // _this.options = []
            _this.isLoading = true
          }
        }, this.delayTime)
        this.isIncluded()
      }
    },
    selectedFind (option) {
      if (this.selected.find(opt => opt.id === option.id)) {
        return true
      } else return false
    },
    onSelect (selected) {
      let val = selected[selected.length - 1]
      if (val != null) {
        if (!this.isIncluded(val)) {
          let valueObject = {}

          valueObject.id = val[this.idField]
          valueObject.label = val[this.labelField]

          valueObject[this.idField] = val[this.idField]
          valueObject[this.labelField] = val[this.labelField]

          this.value.push(valueObject)
        } else {
          this.removeSelectedOption(val)
        }

        this.searchTerm = null
      }
    },
    filter (option, label, search) {
      let temp = search.toLowerCase().trim()

      return (
        option.name.toLowerCase().indexOf(temp) > -1 ||
        ((option.cnpj) && option.cnpj.toLowerCase().indexOf(temp) > -1)
      )
    },
    isIncluded: function (val) {
      // let _me = this
      // let selected = this.value.find((x) => {
      //   return x[_me.idField] === val[_me.idField]
      // })

      // if (selected !== undefined) {
      //   return true
      // }

      // return false
    },
    removeSelectedOption (val) {
      if (val != null) {
        let _this = this
        // Find position of removed item
        let index = this.value.findIndex(function (element) {
          return element[_this.idField] === val[_this.idField]
        })
        // remove element from selected options
        this.value.splice(index, 1)
      }
    }
  },
  computed: {
    filteredOptions: function () {
      let _this = this
      let data = _this.options.filter(function (option) {
        return !_this.isIncluded(option)
      })

      return data.concat(this.value)
    }
  },
  watch: {
    value: function (value, oldValue) {
      if (this.value.length === 0) {
        this.selected = null
      }
    }
  }
}
</script>

<style>
  #fc-dom-1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #A3A0AF !important;
  }
  .style-chooser .vs__search::placeholder,
  .style-chooser .vs__dropdown-toggle,
  .style-chooser .vs__dropdown-menu {
    border: none;
    text-transform: lowercase;
    font-variant: small-caps;
  }
  .style-chooser .vs__dropdown-toggle{
    align-items: flex-start;
  }
  .option-disabled {
    cursor: not-allowed;
    color: #83dadb;
  }
  .ps__thumb-y{
    width: 0px;
    display: none;
  }
  .input{
    border: none !important;
  }
  .anel-load {
  display: inline-block;
  position: relative;
  width: 8px;
  height: 8px;
  }
  .anel-load div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    margin-left: 10px;
    margin-top: -8px;
    border: 8px solid #E0E0E0;
    border-radius: 50%;
    animation: anel-load 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #E0E0E0 transparent transparent transparent;
  }
  .anel-load div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .anel-load div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .anel-load div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes anel-load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
