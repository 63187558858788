<template>
  <div class="itemHeight itemContent mx-1 mb-2" v-if="itemFilterValue && itemFilterKey !== 'hasFilter' && (noFilterList.indexOf(itemFilterKey)) === -1 && !(Array.isArray(itemFilterValue) && itemFilterValue.length <= 0 ) ">
    <!-- Check Filters -->
    <span v-if="this.$store.getters.filtersFieldsCheck.indexOf(this.itemFilterKey) !== -1" ><strong>{{ $t(`filters.${itemFilterKey}`) }}</strong></span>
    <!-- Object type filter -->
    <span v-else-if="this.$store.getters.filtersFieldsObject.indexOf(this.itemFilterKey) !== -1 && itemFilterValue.length > 0  " >
      <strong>{{ $t(`filters.${itemFilterKey}`) }}: </strong>
      <span v-for="(item, index) in itemFilterValue" :key="`item-${index}`">{{ (index !== 0)?', ':'' }}{{ item.label }}</span>
      </span>
    <!-- Multiple Type filter -->
    <span v-else-if="this.$store.getters.filtersFieldsMultiple.indexOf(this.itemFilterKey) !== -1" >
      <strong>{{ $t(`filters.${itemFilterKey}`) }}: </strong><span v-for="(item, index) in itemFilterValue" :key="`item-${index}`">{{ (index !== 0)?', ':'' }}{{ item.label }}</span> </span>
    <!-- Default filter -->
    <span v-else >
      <strong>{{ $t(`filters.${itemFilterKey}`) }}</strong>: {{ formatDate(itemFilterValue) }}</span>
      <div v-if="this.$store.getters.filtersNotEmpty.indexOf(this.itemFilterKey) === -1" class="remove ml-1" v-on:click="removeFilter(itemFilterKey)"><i class="rc-Ativo-36" style="color:#666666"></i></div>
      <div v-else class="remove ml-1">&nbsp;</div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  data: function () {
    return {
      clean: false
    }
  },
  name: 'FilterItemApplied',
  props: {
    itemFilterValue: {
      required: false
    },
    itemFilterKey: {
      type: String,
      required: true
    },
    noFilterList: {
      type: Array,
      required: false
    }
  },
  methods: {
    removeFilter (itemFilterKey) {
      this.clean = true
      if (this.$store.getters.filtersFieldsObject.indexOf(itemFilterKey) !== -1) {
        this.filters[itemFilterKey] = []
      } else {
        this.filters[itemFilterKey] = null
      }

      this.$store.dispatch('setAppliedFilter', this.filters).then(() => {
        this.$store.dispatch('setRefreshFilter', true)
      })
    },
    formatDate (value, locale) {
      locale = locale || 'pt-BR'

      if (dayjs(value).isValid() && this.$store.getters.filtersFieldsDate.indexOf(this.itemFilterKey) >= 0) {
        let options = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }

        let date = new Date(value)

        if (locale === 'pt-BR') {
          options.timeZone = 'America/Sao_Paulo'
          // return date.toLocaleString('pt-BR', options)
        } else {
          options.timeZone = 'America/New_York'
          // return date.toLocaleString('en-US', options)
        }
        return date.toLocaleString(locale, options)
      } else {
        return value
      }
    }
  },
  computed: {
    filters () {
      return this.$store.getters.aplliedFilters || null
    }
  }
}
</script>

<style scoped>
  .itemContent {
    flex: none;
    order: 0;
    flex-grow: 0;
    border: 1px solid #E0E0E0;
    color: #666666;
    display: inline-block;
    padding: 1px 13px;
    background-color: #E0E0E0;
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    padding-top: 7px;
    border-radius: 15px;
  }
  .itemContent .remove {
    display: inline-block;
    /* border: 1px solid #84754E; */
    border-radius: 20px;
    padding: 2px;
  }
  .itemContent .remove > i {
    cursor: pointer;
    position: relative;
    top: -2px;
    right: -2px;
    font-size: 0.5rem;
  }

  .itemContent span, .itemContent strong {
    font-size: 0.80rem;

  }
  .itemHeight{
  min-height: 32px !important;
 }
</style>
